import type { RequestParameters, RequestReturnType, Schemas } from '#shopware';
import type { CableExplorerFilter, CableExplorerFilterOptions } from '~/types/models/cableExplorer';
import { ObjectHelper } from '~/helpers/ObjectHelper';

const MIN_LENGTH_DEFAULT = 0;
const MAX_LENGTH_DEFAULT = 100;

const initialized = ref<boolean>(false);
const hasCableExplorer = ref(false);
const cableExplorerProductsLoaded = ref(false);

const initialFilterValues = ref<CableExplorerFilter>({
    cableType: '',
    series: '',
    includeAdapter: false,
    minLength: MIN_LENGTH_DEFAULT,
    maxLength: MAX_LENGTH_DEFAULT,
});

const selectedFilterValues = ref<CableExplorerFilter>({
    ...initialFilterValues.value,
});

const possiblePlugs = ref<{
    plugA: Schemas['AhCableExplorerPlug'][];
    plugB: Schemas['AhCableExplorerPlug'][];
}>({
    plugA: [],
    plugB: [],
});

const selectedPlugs = ref<{
    plugA: Schemas['AhCableExplorerPlug'] | null;
    plugB: Schemas['AhCableExplorerPlug'] | null;
}>({
    plugA: null,
    plugB: null,
});

export function useCableExplorer() {
    const { apiClient } = useShopwareContext();
    const route = useRoute();
    const { data: cableExplorerData } = useNuxtData('cable-explorer');

    const cableExplorerProducts = computed<string[]>(() => cableExplorerData.value?.products ?? []);
    const hasCableExplorerProducts = computed(() => !!cableExplorerProducts.value?.length);

    const hasActiveFilter = computed(() => {
        return Object.entries(selectedFilterValues.value).some(([key, value]) => {
            // @ts-ignore
            return !['plugA', 'plugB'].includes(key) && initialFilterValues.value[key] !== value;
        });
    });

    const hasAdapterOnlyFilter = computed(() =>
        Object.entries(selectedFilterValues.value).some(([key, value]) => key === 'includeAdapter' && value),
    );

    /**
     * filter out empty/falsy values for request
     * filter out maxLength because it's truthy
     */
    const filteredSelectedFilterValues = computed(() =>
        Object.fromEntries(
            Object.entries(selectedFilterValues.value).filter(([key, val]) => {
                if (hasAdapterOnlyFilter.value) {
                    if (key === 'maxLength' || key === 'minLength') {
                        return false;
                    }
                } else {
                    // Exclude 'maxLength' if its value matches maxCableLength.value
                    if (key === 'maxLength' && val === maxCableLength.value) {
                        return false;
                    }
                    // Exclude 'minLength' if its value matches minCableLength.value
                    if (key === 'minLength' && val === minCableLength.value) {
                        return false;
                    }
                }

                // return other truthy values
                return val;
            }),
        ),
    );

    const filterOptions = computed<CableExplorerFilterOptions>(() => {
        const options = {
            cableTypes: {},
            series: {},
            minLength: MIN_LENGTH_DEFAULT,
            maxLength: MAX_LENGTH_DEFAULT,
        };

        if (!cableExplorerData.value) return options;

        if (
            cableExplorerData.value.cableTypes &&
            ObjectHelper.isObject(cableExplorerData.value.cableTypes) &&
            Object.keys(cableExplorerData.value.cableTypes).length
        ) {
            options.cableTypes = cableExplorerData.value.cableTypes;
        }

        if (
            cableExplorerData.value.series &&
            ObjectHelper.isObject(cableExplorerData.value.series) &&
            Object.keys(cableExplorerData.value.series).length
        ) {
            options.series = cableExplorerData.value.series;
        }

        if (typeof cableExplorerData.value.minLength === 'number' && cableExplorerData.value.minLength) {
            options.minLength = cableExplorerData.value.minLength;
        }

        if (typeof cableExplorerData.value.maxLength === 'number' && cableExplorerData.value.maxLength) {
            options.maxLength = cableExplorerData.value.maxLength;
        }

        return options;
    });
    const _sharedPlugs = ref<RequestReturnType<'getPlugCollection'>>([]);

    const _sharedPlugsLoaded = ref(false);
    const _sharedPlugsLoading = ref(false);
    const _sharedPlugsFailed = ref(false);

    const plugs = computed(() => _sharedPlugs.value ?? []);
    const plugsLoaded = computed(() => _sharedPlugsLoaded.value);
    const plugsLoading = computed(() => _sharedPlugsLoading.value);
    const plugsFailed = computed(() => _sharedPlugsFailed.value);

    const minCableLength = computed(() => Math.trunc(filterOptions.value.minLength || 0));
    const maxCableLength = computed(() => Math.trunc(filterOptions.value.maxLength || 100));

    const searchCriteria = computed<Schemas['Criteria']>(() => ({
        ...filteredSelectedFilterValues.value,
    }));

    const getCableProducts = async (): Promise<RequestReturnType<'cableExplorerSearch'> | null> => {
        try {
            const filters = Object.keys(searchCriteria.value).length ? { filters: { ...searchCriteria.value } } : {};
            // @ts-ignore
            const response = await apiClient.invoke('cableExplorerSearch post /cableExplorer/search', filters);

            return response;
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    const getCablePlugs = async (
        type: 1 | 2,
        searchParams: Omit<RequestParameters<'getPlugCollection'>, 'side'>,
    ): Promise<Schemas['AhCableExplorerPlug'][]> => {
        try {
            _sharedPlugsLoading.value = true;
            _sharedPlugs.value = await apiClient.invoke('getPlugCollection post /cableExplorer/listPlugs/{side}', {
                ...searchParams,
                side: type.toString(),
            });
        } catch (error) {
            _sharedPlugsFailed.value = true;
            Logger.captureException(error);
        } finally {
            _sharedPlugsLoaded.value = true;
            _sharedPlugsLoading.value = false;
        }

        return plugs.value;
    };

    const restoreFromRouteQuery = async () => {
        for (const [key, value] of Object.entries(route.query)) {
            if (
                (key !== 'plugA' && key !== 'plugB' && !Object.hasOwn(selectedFilterValues.value, key)) ||
                typeof value !== 'string'
            ) {
                continue;
            }

            if (key === 'plugA' || key === 'plugB') {
                const plugNumber = key === 'plugA' ? 1 : 2;
                possiblePlugs.value[key] = await getCablePlugs(plugNumber, {
                    cableType: String(route.query.cableType ?? ''),
                    series: String(route.query.cableSerie ?? ''),
                    [key]: value,
                });
                const foundPlug = possiblePlugs.value[key].find((plug) => plug.id === value);
                if (foundPlug) {
                    setPlug(plugNumber, foundPlug);
                }
            } else if (key === 'minLength' || key === 'maxLength') {
                selectedFilterValues.value[key] = parseInt(value);
            } else if (key === 'cableType') {
                setCableType(value);
            } else if (key === 'includeAdapter') {
                selectedFilterValues.value[key] = value === 'true';
            } else {
                // @ts-ignore
                selectedFilterValues.value[key] = value;
            }
        }
    };

    const clearFilters = async (all: boolean = false) => {
        const { plugA, plugB } = selectedFilterValues.value;

        if (all) {
            selectedFilterValues.value = {
                ...initialFilterValues.value,
            };
        } else {
            // Reset all filters but not selected plug types
            selectedFilterValues.value = {
                ...initialFilterValues.value,
                plugA,
                plugB,
            };
        }
    };

    const setPlug = (number: 1 | 2, plug?: Schemas['AhCableExplorerPlug']) => {
        const key = number === 1 ? 'plugA' : 'plugB';

        selectedFilterValues.value[key] = plug?.id ?? '';
        selectedPlugs.value[key] = plug ? { ...plug } : null;
    };

    const setCableType = (type: string) => {
        selectedFilterValues.value.cableType = type;
    };

    const setAndUpdateFilterValues = () => {
        if (!cableExplorerData.value) return;
        if (!initialized.value) {
            if (selectedFilterValues.value.minLength === initialFilterValues.value.minLength) {
                selectedFilterValues.value.minLength = minCableLength.value;
            }
            if (selectedFilterValues.value.maxLength === initialFilterValues.value.maxLength) {
                selectedFilterValues.value.maxLength = maxCableLength.value;
            }

            initialFilterValues.value.minLength = minCableLength.value;
            initialFilterValues.value.maxLength = maxCableLength.value;
            initialized.value = true;
        } else {
            if (minCableLength.value > selectedFilterValues.value.minLength) {
                selectedFilterValues.value.minLength = minCableLength.value;
            }
            if (maxCableLength.value < selectedFilterValues.value.maxLength) {
                selectedFilterValues.value.maxLength = maxCableLength.value;
            }
        }
    };

    if (import.meta.client) {
        watch(cableExplorerData, () => {
            setAndUpdateFilterValues();
        });
    }

    return {
        initialized,
        hasCableExplorer,
        cableExplorerProductsLoaded,
        cableExplorerProducts,
        hasCableExplorerProducts,
        filterOptions,
        minCableLength,
        maxCableLength,
        plugs,
        getCablePlugs,
        getCableProducts,
        searchCriteria,
        plugsLoaded,
        plugsLoading,
        plugsFailed,
        possiblePlugs,
        selectedPlugs,
        selectedFilterValues,
        filteredSelectedFilterValues,
        hasActiveFilter,
        restoreFromRouteQuery,
        clearFilters,
        setPlug,
        setCableType,
        setAndUpdateFilterValues,
    };
}
